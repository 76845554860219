import React from 'react'
import { useState } from 'react'
import { Card, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import Layout from '../components/Layout'

export default function Subscription() {
    const [ShowBorder, setShowBorder] = useState()
    function formatString(e) {
        var inputChar = String.fromCharCode(e.keyCode);
        var code = e.keyCode;
        var allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
            return;
        }

        e.target.value = e.target.value.replace(
            /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
        ).replace(
            /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
        ).replace(
            /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
        ).replace(
            /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
            /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
        ).replace(
            /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
        ).replace(
            /\/\//g, '/' // Prevent entering more than 1 `/`
        );
    }

    return (
        <Layout>
            <main>
                <section className='section-subscription'>
                    <Container>
                        <div className="col-xl-12 col-lg-12 col-md-12 pt-3 mx-auto text-center">
                            <h1 className="fs-48 fw-800 mb-5">Choose Your Plan</h1>
                        </div>
                        <Col xl="11" className="ms-auto">
                            <Row className='mb-4'>
                                <Col xl="5" lg='5' md='12' className='mb-3'>
                                    <h2 className="fw-airlight">Select plans</h2>
                                </Col>
                                <Col xl="7" lg="7" md="12" className="text-center mx-auto">
                                    <Row className="mb-4">
                                        <Col xl="4" lg="5" md="6" xs="6">
                                            <Card className={`border-1 ${ShowBorder === 0 ? "border-color" : ""} `} onClick={() => {
                                                setShowBorder(0)
                                            }}>
                                                <Card.Body>

                                                    <img src="../img/diamond.svg" alt="Silver" className='mb-3' />
                                                    <h5 className="fw-bold">
                                                        Silver User
                                                    </h5>
                                                    <p>
                                                        100 AED per month, or <br />
                                                        1,000 AED per year
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xl="4" lg="5" md="6" xs="6">
                                            <Card className={`border-1 ${ShowBorder === 1 ? "border-color" : ""}`} onClick={() => {
                                                setShowBorder(1)
                                            }}>
                                                <Card.Body>
                                                    <img src="../img/diamondGold.svg" alt="Gold" className='mb-3' />
                                                    <h5 className="fw-bold">
                                                        Gold User
                                                    </h5>
                                                    <p>
                                                        150 AED per month, or <br />
                                                        1,500 AED per year
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form>
                                            <Col xl="10" lg="10" md="12" xs="12" className='text-start'>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="bogo-par fs-13 text-start fw-airlightf">Billing Cycle</Form.Label>
                                                    <Form.Select className="border-0 py-3">
                                                        <option selected>select</option>
                                                        <option value={1}>Monthly</option>
                                                        <option value={2}>Annual</option>
                                                    </Form.Select>

                                                    <div class="d-flex justify-content-between mt-2">
                                                        <Form.Text className="text-muted fw-airlightf">
                                                            Next billing cycle
                                                        </Form.Text>
                                                        <Form.Text className="text-muted fw-airlightf">
                                                            Monthly from 24/11/2021
                                                        </Form.Text>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="5" lg='5' md='12' className='mb-3'>
                                    <h2 className="fw-airlight">Payment method</h2>
                                </Col>
                                <Col xl="7" lg="7" md="12" className="mx-auto">
                                    <Form>
                                        <Col xl="10" lg="10" md="12" xs="12" className='text-start'>
                                            <Form.Label className="bogo-par fs-13 text-start fw-airlightf">Card Number</Form.Label>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    placeholder="5214 5245 2154 XXXX"
                                                    aria-label="5214 5245 2154 XXXX"
                                                    aria-describedby="Card"
                                                />
                                                <InputGroup.Text id="Card" className='bg-input'><img src="../img/visa.png" alt='Visa' /></InputGroup.Text>
                                            </InputGroup>
                                            <Form.Group className="mb-3" controlId="CardHold">
                                                <Form.Label className="bogo-par fs-13 text-start fw-airlightf">CardHold name</Form.Label>
                                                <Form.Control type="text" placeholder="Jane" />
                                            </Form.Group>
                                            <Row className="mb-3">
                                                <Col xl="4" lg="4" md="6" xs="6">
                                                    <Form.Group controlId="Expiry">
                                                        <Form.Label>Expiry date</Form.Label>
                                                        <Form.Control type="text" placeholder="MM/YY" maxlength='5' onChange={(e) => {
                                                            formatString(e)
                                                        }} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl="4" lg="4" md="6" xs="6">
                                                    <Form.Group controlId="CVV">
                                                        <Form.Label>CVV</Form.Label>
                                                        <Form.Control type="text" placeholder="CVV" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Col xl="8" lg="8" md="12">
                                                <Form.Label className="bogo-par fs-13 text-start fw-airlightf">Promo Code</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <FormControl
                                                        placeholder="5214 5245 2154 XXXX"
                                                        aria-label="5214 5245 2154 XXXX"
                                                        aria-describedby="Promo"
                                                    />
                                                    <InputGroup.Text id="Promo" className='bg-input primary-color fs-16'>Redeem</InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                            <div class="d-flex justify-content-between mt-5">
                                                <Form.Text className="text-muted fw-airlightf">
                                                   To pay now
                                                </Form.Text>
                                                <Form.Text className="fw-bold primary-color">
                                                    AED 150
                                                </Form.Text>
                                            </div>
                                            <button type="button" class="=text-start mt-2 w-100 btn btn-primary">
                                                Select
                                             </button>
                                        </Col>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </section>
            </main>
        </Layout>
    )
}
